import { Injectable } from '@angular/core';
import { conf } from '../environments/env.conf';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  constructor() { }
  disableLog() {
    if (conf.production) {
      console.log = function (): void { };
      console.debug = function (): void { };
      console.warn = function (): void { };
      console.info = function (): void { };
      const originalError = console.log;
      console.log = function (...args: any[]): void {
        if (args.includes('Page Not Found')) {
          originalError.apply(console, args);
        }
      };
    }
  }
}
