import { AfterViewInit, Component } from '@angular/core';
import { FooterComponent } from '../footer/footer.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notfound',
  standalone: true,
  imports: [
    FooterComponent
  ],
  templateUrl: './notfound.component.html',
  styleUrl: './notfound.component.css'
})
export class NotfoundComponent implements AfterViewInit {
  constructor(private router: Router) { }

  async ngAfterViewInit() {
    console.error('Page Not Found');
    const element = document.getElementById('CookiesModal');
    if (element) {
      element.style.display = 'none';
    }
  }
}
